import styled from '@emotion/styled';
import { a } from 'react-spring';

export const FlagsStyle = styled.img`
  position: absolute;
  top: -4px;
  right: 0;
  left: -8px;
  bottom: 0;
  width: 116px;

  @media (min-width: 768px) {
    width: 100%;
  }

  @media (min-width: 1280px) {
    top: -6px;
  }
`;

export const CoberturaContainer = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  margin: auto;
  width: 80%;
  max-width: 1440px;
  @media (min-width: 992px) {
    flex-direction: row;
    margin: 5rem auto;
    width: 80%;
  }
`;

export const ColumnsCobertura = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const TitleCobertura = styled(a.h2)`
  color: #1f3463;
  margin: 1.5rem 0;
  font-size: 4rem;
  line-height: 1;
  font-family: 'Sofia Pro', sans-serif;
  font-weight: bold;
  letter-spacing: 0px;

  @media (min-width: 1280px) {
    font-size: 8rem;
  }

  span {
    display: block;
  }
`;

export const ParrafoCobertura = styled(a.p)`
  color: #1f3463;
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

export const ListadoPaises = styled.ul`
  color: #1f3463;
  list-style: none;
  display: grid;
  grid-template: auto/repeat(2, 1fr);
  width: 100%;
  justify-content: space-between;
  max-width: 550px;
  gap: 12px 60px;

  @media (min-width: 768px) {
    gap: 40px 60px;
  }

  @media (min-width: 1280px) {
    gap: 25px 104px;
  }

  li {
    position: relative;
    margin: 0.6rem 0rem;
    width: auto;
    height: 20px;
    filter: drop-shadow(0 3px 6px #00000029);
    &:last-of-type span {
      line-height: 1.1;
      padding: 6px 2rem;
      text-align: center;
    }
    @media only screen and (min-width: 992px) and (max-width: 1020px) {
      width: 190px;
      height: 45px;
    }
  }
`;

export const LabelContainer = styled.div`
  position: absolute;
  right: 9%;
  background: #fff;
  border-radius: 50px;

  display: flex;
  align-items: center;
  height: 115%;
  width: 68px;

  @media (min-width: 768px) {
    width: 130px;
    height: 5.2rem;
    right: 7%;
  }

  @media (min-width: 1280px) {
    width: 116px;
    height: 4.5rem;
    right: 16px;
    padding: 0 2rem;
  }
`;

export const CountryLabel = styled.p`
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  margin: auto;

  @media (min-width: 768px) {
    font-size: 1.4rem;
  }

  @media (min-width: 1280px) {
    font-size: 1.6rem;
    line-height: 1;
    font-size: 1.4rem;
  }
`;

export const ColumnInfoCobertura = styled.div`
  width: 100%;
  max-width: 720px;
  @media (min-width: 992px) {
    width: 50%;
  }
`;

export const TitleAgentes = styled(a.h2)`
  font-size: 4rem;
  color: #1f3463;
  line-height: 1;
  margin-top: 2rem;
  font-family: 'Sofia Pro', sans-serif;

  @media (min-width: 1280px) {
    font-size: 6rem;
  }
  span {
    display: block;
  }
`;

export const ParrafoAgentes = styled(a.p)`
  color: #1f3463;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
  width: 290px;

  @media (min-width: 1280px) {
    width: 340px;
  }
`;

export const ColumnMapCobertura = styled(a.div)`
  width: 100%;
  max-width: 100%;
  position: relative;
  margin-top: 2rem;

  @media (min-width: 768px) {
    margin-top: 8rem;
  }

  @media (min-width: 992px) {
    width: 50%;
    margin-top: 0;
  }
`;

export const MapaImagenStyle = styled.img`
  width: 100%;
  @media (min-width: 1280px) {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: -60px;
  }
`;

export const ContentIconsCities = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 25px;

  @media (min-width: 1280px) {
    gap: 125px;
  }
`;

export const ContentIconText = styled(a.div)`
  width: 29%;
  text-align: center;
`;

export const TextCity = styled.p`
  color: #ed672b;
  font-size: 1.4rem;
  text-align: center;
  margin: 0.8rem 0rem 2rem;
  font-weight: bold;
`;

export const NewYorkImage = styled.img``;

export const NewYersyImage = styled.img``;

export const PennsylvaniaImage = styled.img``;

export const LindenOffice = styled(a.div)`
  background: #fff;
  padding: 1rem;
  color: #1f3463;
  position: absolute;
  width: 127px;
  right: 3rem;
  top: -2rem;
  border-radius: 8px;
  box-shadow: 2px 3px 6px #00000029;
  z-index: 1;

  @media (min-width: 768px) {
    right: 15rem;
    top: 5rem;
  }

  @media (min-width: 1280px) {
    top: 0rem;
    right: 15rem;
    width: 160px;
    height: 132px;
  }

  h3 {
    font-size: 1.3rem;
    width: 100%;
    line-height: 1.2;

    @media (min-width: 1280px) {
      font-size: 1.4rem;
      width: 91px;
    }
  }
  p {
    font-size: 1rem;

    @media (min-width: 1280px) {
      font-size: 1.2rem;
    }
  }
`;

export const BogotaOffice = styled(a.div)`
  background: #fff;
  padding: 1rem;
  color: #1f3463;
  position: absolute;
  top: 219px;
  left: 40px;
  width: 127px;
  border-radius: 8px;
  box-shadow: 2px 3px 6px #00000029;
  z-index: 1;

  @media (min-width: 768px) {
    top: 453px;
    left: 170px;
  }

  @media (min-width: 1280px) {
    top: 35rem;
    left: 6rem;
    width: 160px;
  }

  h3 {
    font-size: 1.3rem;
    width: 58px;
    line-height: 1.2;

    @media (min-width: 1280px) {
      font-size: 1.4rem;
      width: 91px;
    }
  }
  p {
    font-size: 1rem;

    @media (min-width: 1280px) {
      font-size: 1.2rem;
    }
  }
`;

export const CasitaStyleLinden = styled.img`
  background: #ed672b;
  width: 30px;
  height: 30px;
  padding: 0.5rem;
  border-radius: 50%;
  position: absolute;
  top: -15px;
  right: -15px;

  @media (min-width: 1280px) {
    padding: 1rem;
    width: 50px;
    height: 50px;
    top: -25px;
    right: -25px;
  }
`;

export const CasitaStyleBogota = styled.img`
  background: #ed672b;
  width: 30px;
  height: 30px;
  padding: 0.5rem;
  border-radius: 50%;
  position: absolute;
  top: -15px;
  right: -15px;

  @media (min-width: 1280px) {
    padding: 1rem;
    width: 50px;
    height: 50px;
    top: -25px;
    right: -25px;
  }
`;

export const Divider = styled(a.hr)`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 2px;
  margin: 5.7rem 0 2.4rem;
  @media (min-width: 1280px) {
    margin: 5.7rem 0 2.4rem;
    color: ${({ theme }) => theme.colors.primary};
    border: 0.1rem solid;
  }
`;

export const AuthorizedContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
